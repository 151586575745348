import React from 'react';
import PropTypes, { shape, arrayOf } from 'prop-types';
import cx from 'classnames';
import { Link, graphql } from 'gatsby';

import styles from './ppp-loan-forgiveness.module.sass';
import timelineImage from './ppp-timeline.png';
import Layout from '../../components/layout';
import CtaCard from '../../components/blocks/cta-card';
import Banner from '../../components/blocks/banner';
import FluidImage from '../../components/common/image/fluid';
import Footer from '../../components/common/footer';
import SEO from '../../components/common/seo';

const PppLoanForgiveness = ({
  data: {
    allMarkdownRemark: { nodes },
    guidelines,
    ultimateGuide,
  },
}) => {
  return (
    <Layout footer={false}>
      <SEO
        title="Apply for Paycheck Protection Program (PPP) loan forgiveness"
        description="SMBs can apply for loan forgiveness for SBA Paycheck Protection Program loans."
        urlPath="/ppp-loan-forgiveness/"
      />
      {/* <Banner background="grey">
        <p>
          Current SBA guidelines state, First Draw PPP loans made to eligible
          borrowers qualify for full loan forgiveness if, during the 8- to
          24-week covered period following loan disbursement:
        </p>
        <ul className={styles.bannerList}>
          <li>Employee and compensation levels are maintained,</li>
          <li>
            The loan proceeds are spent on payroll costs and other eligible
            expenses; and
          </li>
          <li>At least 60% of the proceeds are spent on payroll costs.</li>
          <li>
            For More Information visit:{' '}
            <a
              href="https://
            www.sba.gov/funding-programs/loans/
            covid-19-relief-options/paycheckprotection-program/ppp-loan-forgiveness"
              target="_blank"
              rel="noreferrer"
            >
              https://www.sba.gov/funding-programs/loans/covid-19-relief-options/paycheckprotection-program/ppp-loan-forgiveness
            </a>
          </li>
        </ul>
      </Banner> */}
      <CtaCard>
        <h1>Paycheck Protection Program (PPP) Loan Forgiveness</h1>
        <p className={styles.heroText}>
          Get up to 100% of your PPP loan forgiven based on your employee
          status, payroll and how you used the loan funds.
        </p>
        <Link className="cta" to={guidelines.frontmatter.urlPath}>
          Learn More
        </Link>
      </CtaCard>
      <section className={styles.timeline}>
        <div className="container">
          <h2 className={cx('green', styles.timelineHeading)}>
            Here’s how it works
          </h2>
          <div className={styles.wideImage}>
            <img
              src={timelineImage}
              width="1000px"
              alt="Loan Forgiveness Timeline"
              role="presentation"
            />
          </div>
          <ul className={styles.list}>
            <li>
              <p>
                If employees and payroll have remained consistent with pre-COVID
                levels, you can have up to 100% of your loan forgiven.
              </p>
            </li>
            <li>
              <p>
                The SBA will forgive the portion of the loan used for payroll,
                rent, mortgage interest or utility payments for up to 24 weeks
                after the loan is issued.
              </p>
            </li>
            <li>
              <p>
                Any amount that is not forgiven will be treated as a two-year
                loan with a 1% fixed interest rate.<sup>1</sup>
              </p>
            </li>
            <li>
              <p>
                All payments (principal and interest) are deferred until you
                receive a decision on your loan forgiveness application. However,
                interest will continue to accrue over this period. If your forgiveness
                application was submitted after your deferral period, payments are due
                while the application is under review.
              </p>
            </li>
          </ul>
        </div>
      </section>
      <CtaCard background="green">
        <h2>Preparing for loan forgiveness</h2>
        <p>
          Check out the latest eligibility guidelines and learn what documents
          prepare for your business type.
        </p>
        <Link className="cta" to={guidelines.frontmatter.urlPath}>
          Learn More
        </Link>
      </CtaCard>
      <CtaCard background="grey">
        <h2>How loan forgiveness is calculated</h2>
        <p>
          To calculate how much of your loan can be forgiven, your lender will
          use the responses from your application to:
        </p>
        <ol className={styles.numberedList}>
          <li>
            <div className={styles.number}>1</div>
            <div className={styles.content}>
              <p>
                <b>
                  Determine the maximum amount of possible loan forgiveness.
                </b>
              </p>
              <p>
                This is based on how much you spent on payroll and other
                eligible expenses like rent and utilities during the 24-week
                period.
              </p>
            </div>
          </li>
          <li>
            <div className={styles.number}>2</div>
            <div className={styles.content}>
              <p>
                <b>
                  Calculate the amount (if any) by which the maximum loan
                  forgiveness will be reduced.
                </b>
              </p>
              <p>
                If you reduced your number of employees or their wages during
                the 24-week period, your loan forgiveness amount will be
                reduced.<sup>2</sup>
              </p>
            </div>
          </li>
          <li>
            <div className={styles.number}>3</div>
            <div className={styles.content}>
              <p>
                <b>
                  Understand how much of your loan was used on eligible
                  expenses.
                </b>
              </p>
              <p>
                Only 40% of your PPP loan can be used for expenses other than
                payroll for certain business structures. If you used more than
                40% on non-payroll expenses, your forgiveness amount will be
                reduced by the percentage of the loan you used on ineligible
                expenses.
              </p>
            </div>
          </li>
        </ol>
        <Link className="cta" to={guidelines.frontmatter.urlPath}>
          Learn More
        </Link>
      </CtaCard>
      <section className={styles.blog}>
        <div className="container">
          <h2 className={styles.blogHeader}>
            Learn more about loan forgiveness
          </h2>
          <div className={styles.blogPosts}>
            <ul>
              {nodes.map(
                ({ frontmatter: { urlPath, title, featuredImage } }) => {
                  return (
                    <li key={urlPath}>
                      <FluidImage image={featuredImage} />
                      <Link className="text-link--black" to={urlPath}>
                        {title}
                      </Link>
                    </li>
                  );
                },
              )}
            </ul>
          </div>
          <div className={styles.divider} />
          <div>
            <h3 className={styles.pppFaq}>
              Review our FAQs for details about applying for loan forgiveness.
            </h3>
            <Link
              to={ultimateGuide.frontmatter.urlPath}
              className="pill-button"
            >
              Review FAQs
            </Link>
          </div>
          <div className={styles.scamDisclaimer}>
            <h3>Be aware of potential scams</h3>
            <p>
              We want to make sure you and your business are protected and get
              the critical funding you need during this crisis.{' '}
              <Link to={ultimateGuide.frontmatter.urlPath}>
                Review our FAQs
              </Link>{' '}
              for signs of potential SBA fraud, which you can report{' '}
              <a
                href="https://www.sba.gov/about-sba/oversight-advocacy/office-inspector-general/office-inspector-general-hotline#section-header-0"
                target="_blank"
                rel="noreferrer"
              >
                online
              </a>{' '}
              or through the Office of Inspector General&apos;s hotline at{' '}
              <a href="tel:800-767-0385" className={styles.phone}>
                800-767-0385
              </a>
              .
            </p>
          </div>
        </div>
      </section>
      <Footer>
        <li value="1">
          PPP loans issued after 6/5/2020 will have a 5-year term.
        </li>
        <li>
          Any reduction in an employee’s salary or hourly wages of more than 25%
          will be subtracted from your eligible loan forgiveness amount. If you
          laid off an employee, then offered to rehire the same employee for the
          same salary or wage and number of hours, but they declined the offer,
          your loan may still be forgiven for the full amount. You “must have
          made a good faith, written offer of rehire, and the employee’s
          rejection of that offer must be documented.”
        </li>
      </Footer>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      limit: 3
      sort: { fields: frontmatter___forgiveness_rank, order: ASC }
    ) {
      nodes {
        frontmatter {
          urlPath
          title
          featuredImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    guidelines: markdownRemark(
      frontmatter: { id: { eq: "e29bc48d-50d5-4582-9ed4-a090399d7708" } }
    ) {
      frontmatter {
        urlPath
      }
    }
    ultimateGuide: markdownRemark(
      frontmatter: { id: { eq: "c4c249b9-73c8-5352-bbec-0da99f7b6d3a" } }
    ) {
      frontmatter {
        urlPath
      }
    }
  }
`;

PppLoanForgiveness.propTypes = {
  data: shape({
    allMarkdownRemark: shape({
      nodes: arrayOf(
        shape({
          frontmatter: shape({
            urlPath: PropTypes.string,
            title: PropTypes.string,
            // eslint-disable-next-line react/forbid-prop-types
            featuredImage: PropTypes.object,
          }),
        }),
      ),
    }),
    guidelines: shape({ frontmatter: shape({ urlPath: PropTypes.string }) }),
    ultimateGuide: shape({
      frontmatter: shape({ urlPath: PropTypes.string }),
    }),
  }).isRequired,
};

export default PppLoanForgiveness;
