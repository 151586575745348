import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './cta-card.module.sass';

const CtaCard = ({ children, className, background }) => {
  const modifyChild = (child) => {
    const { type } = child;
    const extraClasses = [];

    if (['h1', 'h2', 'h3'].includes(type)) {
      extraClasses.push(styles.heading);
      background === 'green' && extraClasses.push(styles.headingWhite);
      background === 'grey' && extraClasses.push(styles.headingBlack);
    } else if (type === 'p') {
      extraClasses.push(styles.paragraph);
    } else if (
      type === 'a' ||
      child.props?.className?.split(' ')?.includes('cta')
    ) {
      extraClasses.push(
        'pill-button',
        { 'pill-button--white': background === 'green' },
        styles.ctaButton,
      );
    }

    return React.cloneElement(child, {
      ...child.props,
      className: cx(child.props?.className, ...extraClasses),
    });
  };

  return (
    <section
      className={cx({
        [styles.sectionGreen]: background === 'green',
        [styles.sectionGrey]: background === 'grey',
      })}
    >
      <div className={cx('container', styles.container, className)}>
        {React.Children.map(children, (child) => modifyChild(child))}
      </div>
    </section>
  );
};

CtaCard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  background: PropTypes.oneOf(['white', 'green', 'grey']),
};

CtaCard.defaultProps = {
  className: '',
  background: 'white',
};

export default CtaCard;
